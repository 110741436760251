import React, { useState } from 'react';
import './AdminCreateOrder.scss';
import OrderService from "../../services/OrderService";

function AdminCreateOrder() {
    const [orderId, setOrderId] = useState('');
    const [orderSpeed, setOrderSpeed] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Скорость доставки');
    const [errorFields, setErrorFields] = useState({});

    const options = ['Быстрая', 'Медленная'];

    const handleOrderCreation = async () => {
        let errors = {};
        if (!orderId) errors.orderId = true;
        if (!orderSpeed || orderSpeed === 'Скорость доставки') errors.orderSpeed = true;

        if (Object.keys(errors).length > 0) {
            setErrorFields(errors);
            return;
        }

        setErrorFields({});
        try {
            await OrderService.create(orderId, orderSpeed);
            setOrderId("");
            setOrderSpeed("");
            setSelectedOption('Скорость доставки');
        } catch (e) {
            console.error("Ошибка создания заказа:", e);
            setOrderId("");
            setOrderSpeed("");
            setSelectedOption('Скорость доставки');
        }
    };

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsDropdownOpen(false);
        setOrderSpeed(option);
    };

    return (
        <div className="create">
            <div className="input-container">
                <input
                    type="text"
                    className={`order-input ${errorFields.orderId ? 'error' : ''}`}
                    placeholder="Введите номер заказа"
                    value={orderId}
                    onChange={(e) => setOrderId(e.target.value)}
                />
                <div className={`custom-select ${errorFields.orderSpeed ? 'error' : ''}`}>
                    <div
                        className="select-header"
                        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                        {selectedOption}
                        <span className={`arrow ${isDropdownOpen ? 'open' : ''}`}>▼</span>
                    </div>
                    {isDropdownOpen && (
                        <ul className="select-options">
                            {options.map((option, index) => (
                                <li
                                    key={index}
                                    className="select-option"
                                    onClick={() => handleOptionClick(option)}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
                <button className="create-btn" onClick={handleOrderCreation}>
                    Создать заказ
                </button>
            </div>
        </div>
    );
}

export default AdminCreateOrder;
