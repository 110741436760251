import $api from "../http/index";
import {OrderResponse, TrackResponse} from "../http/types";
import {AxiosResponse} from 'axios';


export default class OrderService {
    static async track(id: string): Promise<AxiosResponse<TrackResponse>> {
        return $api.post<TrackResponse>('/track', {id})
    }

    static async create(id: string, speed: string): Promise<AxiosResponse<void>> {
        return $api.post('/create', { id, speed });
    }

    static async getAll(): Promise<OrderResponse[]> {
        const response = await $api.get<OrderResponse[]>('/getAll');
        return response.data;
    }

    static async pause(): Promise<AxiosResponse<void>> {
        return $api.get('/pause');
    }

    static async resume(): Promise<AxiosResponse<void>> {
        return $api.get('/resume');
    }
}